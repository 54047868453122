/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }


mat-sidenav[style] {
    visibility: visible !important;
}
.mat-drawer {
    transform: none !important;
}

.p-datatable .p-datatable-header {
    padding: 8px 16px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
    padding: 6px 10px;
    flex-wrap: nowrap;
    white-space: nowrap;
}

.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
    padding: 6px 10px;
    flex-wrap: nowrap;
    white-space: nowrap;
}